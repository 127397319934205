<template>
    <div>
        <ul class="tab-navs spmg-bottom-db">
            <li class="tn-item current">
                个人信息
            </li>
        </ul>
        <ul class="com-table">
            <li>
                <span>头像</span>
                <div class="display-mode">
                    <div class="member_cover" @click="dialogVisible=true">
                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                    </div>
                </div>
            </li>
            <li>
                <span>手机号</span>
                <div class="display-mode">
                    <strong>{{form.account}}</strong>
                </div>
            </li>
            <li>
                <span>用户名</span>
                <div class="display-mode" :class="istrue==0?'hide':''">
                    <strong>{{form.name}}</strong>
                    <a class="ctrl j-edit"><i class="icf el-icon-edit" @click="valBox()"></i></a>
                </div>
                <form class="edit-mode" :class="istrue==1?'hide':''">
                    <input type="text" class="form-control" v-model="value">
                    <a class="ctrl j-apply"><i class="icf el-icon-check" @click="rightBox()"></i></a>
                    <a class="ctrl j-cancel"><i class="icf el-icon-close" @click="wrong()"></i></a>
                    <label class="error" for="nickname">{{msg}}</label>
                </form>
            </li>
            <li>
                <span>注册时间</span>
                <div class="display-mode">
                    <strong>{{form.time}}</strong>
                </div>
            </li>
            <li>
                <span>用户类型</span>
                <div class="display-mode">
                    <i class="icon-vip-none"></i>
                    <em>{{form.vip==false?'普通用户':'高校用户'}}</em>
                </div>
            </li>
        </ul>   
        <!-- 弹窗 -->
        <div class="member_Popup">
            <el-dialog
                title="编辑头像"
                :visible.sync="dialogVisible"
                :show-close="false"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                width="600px"
            >
                <div style="display: flex" class="avatar">
                <div class="avatar-left">
                    <div v-show="!options.img">
                    <el-upload
                        ref="upload"
                        :action="uploadimage"
                        style="text-align: center;margin-bottom: 24px"
                        :on-change="uploads"
                        accept="image/png, image/jpeg, image/jpg"
                        :show-file-list="false"
                        :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary" ref="uploadBtn">选择图片</el-button>
                    </el-upload>
                    <div>支持jpg、png格式的图片，大小不超过3M</div>
                    </div>
                    <div v-show="options.img" class="avatar-left-crop">
                    <vueCropper
                        class="crop-box"
                        ref="cropper"
                        :img="options.img"
                        :autoCrop="options.autoCrop"
                        :fixedBox="options.fixedBox"
                        :canMoveBox="options.canMoveBox"
                        :autoCropWidth="options.autoCropWidth"
                        :autoCropHeight="options.autoCropHeight"
                        :centerBox="options.centerBox"
                        @realTime="realTime">
                    </vueCropper>
                    <p class="avatar-left-p">
                        鼠标滚轮缩放控制图片显示大小，鼠标拖拽调整显示位置</p>
                    </div>
                </div>
                <div class="avatar-right">
                    <div class="avatar-right-div" v-for="(item,index) in previewsDiv" :key="index" :style="item.style">
                    <div v-show="options.img" :class="previews.div" class="avatar-right-previews" :style="item.zoomStyle">
                        <img :src="previews.url" :style="previews.img">
                    </div>
                    </div>
                    <div class="avatar-right-text">
                    <el-button v-if="options.img" type="text" @click="uploadPreviews">重新上传</el-button>
                    <span v-else>预览</span>
                    </div>
                </div>
                </div>
                <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="getCrop">确 定</el-button>
            </span>
            </el-dialog>
        </div>
    </div>
</template> 
<script>
import axios from 'axios'
export default {
    data () {
        return {
            istrue:1,
            msg:'',
            value:'',
            form:{},
            uploadimage:"",
            imageUrl:'',
            dialogVisible:false,
            options: {
                img: '',  //原图文件
                autoCrop: true,  //默认生成截图框
                fixedBox: true,  //固定截图框大小
                canMoveBox: false,    //截图框不能拖动
                autoCropWidth: 200,  //截图框宽度
                autoCropHeight: 200, //截图框高度
                centerBox: true    //截图框被限制在图片里面
            },
            //实时预览图数据
            previews: {},
            //实时预览图样式
            previewsDiv: [
                //108px 预览样式
                {
                    style: {
                        width: '108px',
                        height: '108px',
                        margin: '0 auto'
                    },
                    zoomStyle: {
                        transform:'scale(0.54,0.54)',
                        transformOrigin:'top left'
                    }
                },
                //68px 预览样式
                {
                    style: {
                        width: '68px',
                        height: '68px',
                        margin: '27px auto'
                    },
                    zoomStyle: {
                        transform:'scale(0.34,0.34)',
                        transformOrigin:'top left'
                    }
                },
                //48px 预览样式
                {
                    style: {
                        width: '48px',
                        height: '48px',
                        margin: '0 auto'
                    },
                    zoomStyle: {
                        transform:'scale(0.24,0.24)',
                        transformOrigin:'top left'
                    }
                }
            ]
        }
    },
    methods: {
        getapp(){
            this.imageUrl = this.$store.state.avatar
            this.uploadimage = this.$store.state.uploadimage
            this.axios.myinfo({
                token:this.$store.state.token
            }).then(res=>{
                if(res.data.code==0){
                    this.form = res.data.data
                }
            }).catch(err=>{

            })
        },
        valBox(){
            this.value = this.form.name
            this.istrue= 0
        },
        rightBox(){
            if(this.value==''){
                this.msg='请输入值'
            }else if(this.value.length > 6){
                this.msg='不超过6个字符'
            }else{
                this.axios.myupdate({
                    token:this.$store.state.token,
                    name:this.value
                }).then(res=>{
                    if(res.data.code==0){
                        this.$store.dispatch("userName",res.data.data.name)
                        this.istrue=1
                        this.getapp()
                    }else{
                        this.$message.info(res.data.message)
                    }
                }).catch(err=>{

                })
            }
        },
        wrong(){
            this.istrue = 1
        },
        //读取原图
        uploads(file) {
            const isIMAGE = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
            const isLt3M = file.raw.size / 1024 / 1024 < 3;
            if (!isIMAGE) {
                this.$message({
                    showClose: true,
                    message: '请选择 jpg、png 格式的图片！',
                    type: 'error'  //提示类型
                });
                return false;
            }
            if (!isLt3M) {
                this.$message({
                    showClose: true,
                    message: '上传图片大小不能超过 3MB',
                    type: 'error'  //提示类型
                });
                return false;
            }
            let data = window.URL.createObjectURL(new Blob([file.raw]));
            this.fileName = file.name
            this.options.img = data;
        },
        //实时预览数据
        realTime(data) {
            this.previews = data
        },
        //重新上传
        uploadPreviews() {
            this.$refs.uploadBtn.$el.click()
        },
        //获取截图信息
        getCrop() {
            // 获取截图的 base64 数据
            let formData = new FormData();
            this.$refs.cropper.getCropBlob((data) => {
                formData.append("file", data,this.fileName);
                axios.post(this.uploadimage,formData).then(res=>{
                    if(res.data.code==0){
                        this.axios.myupdate({
                            token:this.$store.state.token,
                            avatar:res.data.data
                        }).then(res=>{
                            if(res.data.code==0){
                                this.$store.dispatch("avatar",res.data.data.avatar)
                                this.getapp()
                                this.dialogVisible = false
                            }
                        }).catch(err=>{

                        })
                    }
                }).catch(err=>{

                })
                // this.imageUrl = data
                // this.dialogVisible = false
            })
        },
        //关闭弹框
        closeDialog() {
            this.dialogVisible = false
            //重置 data 数据。(Object.assign是对象深复制  this.$data是组件内的数据对象 this.$options.data()是原始的数据)
            Object.assign(this.$data, this.$options.data())
            this.getapp()
        }
    },
    mounted() {
        this.getapp()
    }
}
</script>
<style scoped>
@import "./../../styles/Page/Downloadlatest.css";
</style>
<style>
.member_Popup .el-dialog__header {
    padding: 24px 0 11px 28px;
}
.member_Popup .el-dialog__title {
    color: #333333;
}
.member_Popup .el-dialog__body {
    padding: 0 28px;
}
.member_Popup .el-dialog__footer {
    padding: 20px 28px;
}
.member_Popup .el-button {
    width: 145px;
}
.member_Popup .avatar {
    display: flex;
}
.member_Popup .avatar-left {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 400px;
      height: 400px;
      background-color: #F0F2F5;
      margin-right: 10px;
      border-radius: 4px;
}
.member_Popup .avatar-left-crop {
    width: 400px;
    height: 400px;
    position: relative;
}
.member_Popup .crop-box {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    overflow: hidden
}
.member_Popup .avatar-left-p {
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 20px;
    color: #ffffff;
    font-size: 14px;
}
.member_Popup .avatar-right {
      width: 150px;
      height: 400px;
      background-color: #F0F2F5;
      border-radius: 4px;
      padding: 16px 0;
      box-sizing: border-box;
}
.member_Popup .avatar-right-div {
    border: 3px solid #ffffff;
    border-radius: 50%;
    }

.member_Popup .avatar-right-previews {
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
}
.member_Popup .avatar-right-text {
    text-align: center;
    margin-top: 50px;
    font-size: 14px;
}
.member_Popup .avatar-right-text span {
    color: #666666;
}
</style>